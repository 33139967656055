

body {
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  font-family: 'Lexend Deca', sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.scroll-indicator{
  display: flex;
  justify-content: center;
  margin-top: 50px;
}

.scroll-indicator button {
  animation: bounce 2s infinite;
  border: none;
  background-color: transparent;
  cursor: pointer;
}
@media (max-width: 900px) {
  .scroll-indicator button {
    display: none; 
  }
}

@keyframes bounce {
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-15px);
  }
}
